import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {included, input, menu, short, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpShortWide, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import AddShort from "../components/AddShort";
import EditShort from "../components/EditShort";
import SearchIcon from "@mui/icons-material/Search";
import {shortNumberDelete, shortRequest} from "../store/actions/shortNumber";
import qs from "query-string";
import Utils from "../Utils";
import Api from "../Api";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableLoader from "../components/TableLoader";
import _ from 'lodash'
import Cookies from "js-cookie";

const {REACT_APP_SERVER} = process.env;
const titleArr = [
    sip.note[+localStorage.getItem('atsLang')  || 1],
    sip.phone[+localStorage.getItem('atsLang')  || 1],
    short.short[+localStorage.getItem('atsLang')  || 1],
    short.list[+localStorage.getItem('atsLang')  || 1],
    short.no_answer[+localStorage.getItem('atsLang')  || 1],
];
let descArr = []


let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
function ShortNumber(props) {
    const shortData = useSelector(state => state.shortNumber.shortNumberData)
    const shortNumberStatus = useSelector(state => state.shortNumber.shortNumberStatus)
    const page = useSelector(state => state.shortNumber.page)
    const [copied, setCopied] = useState(false)
    const count = useSelector(state => state.shortNumber.count)
    const [edit, setEdit] = useState(0)
    const [create, setCreate] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await Api.shortDelete(id)
                await dispatch(shortNumberDelete(id))
            }

        }catch (e) {
            console.log(e)
        }
    },[])
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    useEffect(() => {
        (async () => {
            await dispatch(shortRequest(Cookies.get("short") ||query.limit || 10, query.page || 1, query.search || '',query.sortBy || '', query.sortOrder || ''))
        })()
    }, [location.search,sortBy,sortOrder])
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("short",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    const handleSort = (column) => {
        query.sortBy = column
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            query.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setSortBy(column);
            setSortOrder('asc');
            query.sortOrder = 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }
    };
    useEffect(() => {
       if(!_.isEmpty(shortData)){
           shortData?.map(s => {
               descArr.push({notes:s?.name,out:s?.item[0]?.mobnum?.output,virtual:s.virtual,list:s?.item?.length,noAnswer:s.noaction,})
           })
       }
    },[shortData,descArr])
    return (
        <>
            <Helmet>
                <title>
                    {menu.short[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table className={shortNumberStatus === 'request'?"myTableStyle":''} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                            {menu.shortC[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end">
                                                <label className="search">
                                                    <input value={query.search || ''} onChange={handleSearch}
                                                           placeholder={input.search[+localStorage.getItem('atsLang')  || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        >

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            onClick={() => handleSort('name')}
                                        >
                                            {sip.note[+localStorage.getItem('atsLang')  || 1]}
                                            <span>{sortBy === 'name' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            onClick={() => handleSort('mobnum')}
                                        >
                                            {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                            <span>{sortBy === 'mobnum' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('virtual')}
                                            align='center'
                                        >
                                            {short.short[+localStorage.getItem('atsLang')  || 1]}
                                            <span>{sortBy === 'virtual' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('list')}
                                            align='center'
                                        >
                                            {short.list[+localStorage.getItem('atsLang')  || 1]}
                                            <span>{sortBy === 'list' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('noaction')}
                                            align='center'
                                        >
                                            {short.no_answer[+localStorage.getItem('atsLang')  || 1]}
                                            <span>{sortBy === 'noaction' && sortOrder === 'asc' ?<FontAwesomeIcon icon={faArrowUpShortWide} />:<FontAwesomeIcon icon={faArrowDownWideShort} />}</span>

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shortNumberStatus === 'request'?<TableLoader colSpan={10}/>:shortData.length ? shortData.map(s => (
                                        <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center">
                                                {s?.cwaiting?<CheckCircleIcon style={{color:color,fontSize:18}} />:<CheckCircleOutlineIcon style={{color:color,fontSize:18}} />}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.name} {s?.item?.length?s?.item[0]?.member_buddy[0]?.notes:''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.item?.length?s?.item[0].mobnum?.output:''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.virtual}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.item?.length}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.noaction}
                                            </TableCell>
                                            <TableCell className="action" align="center"
                                                       >
                                               <p style={{fontSize: 13, minWidth: 125, display: "flex",justifyContent:'center'}}> <span onClick={() => setEdit(s.id)} className="edit"><FontAwesomeIcon
                                                   icon={faPenToSquare}/></span>
                                                   <span onClick={() => handleDelete(s.id)}
                                                         className="delete"><FontAwesomeIcon icon={faTrashCan}/></span></p>
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                        <TableCell align="center" colSpan={9} sx={{fontSize: 13}}>
                                            no data

                                        </TableCell>

                                    </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel(titleArr, descArr)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel(titleArr, descArr)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.copy(setCopied)}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,{ label: 'All', value: -1 }]}
                                component="div"
                                count={count || 0}
                                rowsPerPage={Cookies.get("short") ||query.limit || 10}
                                page={page - 1 || 0}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev,newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>
                    </Paper>
                    {copied ? <div className="copied">Copied</div> : null}
                </div>
                {create ? <AddShort open={create} setOpen={setCreate}/> : null}
                {edit ? <EditShort open={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>

    );
}

export default ShortNumber;
