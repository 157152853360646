import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {Helmet} from "react-helmet";
import {auto, file, global, input, ivr, menu, short, sip, weekDays} from "../translate";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/material";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";
import Flatpickr from "react-flatpickr";

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto'
};

function AutoCallEdit(props) {
    const [form, setForm] = useState({
        line: '',
        timeout: '',
        name: '',
        time: []
    })

    const [graphic, setGraphic] = useState({
        id: new Date(),
        num: '',
        weekStart: weekDays[1][+localStorage.getItem('atsLang') || 1],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang') || 1],
        timeStart: '10:00',
        timeEnd: '22:59',
        attempt: 2,
        okStart: 0,
        okEnd: 60,
        phone: ''
    })
    const [inAuto, setIn] = useState([])

    const navigate = useNavigate()
    const params = useParams()
    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleTimeChange = useCallback((name, ev) => {
        setGraphic((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleAdd = useCallback(async () => {
        try {
            await Api.editAuto(form, params.id)
            navigate('/auto_call')
        } catch (e) {

        }
    }, [form, params])

    const handleAddGraphic = useCallback(async () => {
        setForm((prev) => ({
            ...prev,
            time: [...prev.time, {...graphic, id: new Date()}]
        }))
    }, [graphic])
    const handleDelete = useCallback(async (id) => {
        try {
            setForm((prev) => ({
                ...prev,
                time: prev.time.filter(t => t.id !== id)
            }))
            if (typeof id === 'number') {
                await Api.deleteItem(id)
            }
        } catch (e) {

        }
    }, [])
    const handleDeleteAll = useCallback(async () => {
        try {
            await Api.deleteAuto(params.id, true)
            setForm((prev) => ({
                ...prev,
                time: []
            }))
        } catch (e) {

        }
    }, [params])

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.singleAuto(params.id)
                setForm({
                    line: data.dataItem.virtual,
                    timeout: data.dataItem.name.timeout,
                    name: data.dataItem.name,
                    time: data.memberGroupsItems.map(s => ({
                            id: s.id,
                            num: data.memberGroups.find(m => +m.azang === +s.buddy)?.azang + '(' + data.memberGroups.find(m => +m.azang === +s.buddy)?.comment + ')',
                            weekStart: weekDays[+s.days.split('-')[0] - 1][+localStorage.getItem('atsLang')  || 1],
                            weekEnd: weekDays[+s.days.split('-')[1] - 1][+localStorage.getItem('atsLang')  || 1],
                            timeStart: s.hours.split('-')[0],
                            timeEnd: s.hours.split('-')[1],
                            attempt: s.rlimit,
                            okStart: s.okinterval,
                            okEnd: s.nokinterval,
                            phone: s.dnumber
                        })
                    )
                })
                setIn(data.memberGroups)
            } catch (e) {
                console.log(e)
            }
        })()
    }, [weekDays, params.id])
    const handleTime = (name, value) => {
        const input = value;
        if (/^([0-9]{0,2}):?([0-9]{0,2})?$/.test(input) || input === '') {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: input,
            }))
        }
    };
    const handleBlur = (name) => {
        if (graphic[name]) {
            const [hours, minutes] = graphic[name].split(':').map(Number);
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            setGraphic((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {menu.autoCall[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <p style={{
                                color: '#a7a7a7',
                                fontSize: 13,
                                marginBottom: 13, marginLeft: 13,
                            }}>
                                {ivr.name[+localStorage.getItem('atsLang')  || 1]}
                            </p>
                            <Link style={{cursor: 'pointer'}} to="/auto_call">
                                <CloseIcon/>

                            </Link>
                        </div>
                        <div className="row" style={{margin: '-20px 0'}}>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                                <input type="text" value={form.name}
                                       onChange={(ev) => handleChange('name', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {auto.line[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input type="number" value={form.line}
                                       onChange={(ev) => handleChange('line', ev.target.value)}/>
                            </label>
                        </div>
                        <div className="short_block" style={{margin: '30px 0',}}>
                            <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                                <div style={{width: 120}}>
                                    {auto.num[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                                <div style={{width: 120}}>
                                    {short.week[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                                <div style={{width: 120}}>
                                    {short.time[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                                <div style={{width: 120}}>
                                    {auto.ok[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                                <div style={{width: 120}}>
                                    {auto.phone[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                                <div style={{width: 70}}>
                                    {auto.attempt[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                                <div style={{width: 120}}>
                                    {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                </div>
                            </div>
                            {form.time.map(s => (
                                <div key={s.id} className="d-flex flex-row text-center"
                                     style={{width: 'fit-content', margin: '10px 0'}}>
                                    <div style={{width: 120}}>
                                   <span>
                                       {s.num}
                                   </span>
                                    </div>
                                    <div style={{width: 120}}>
                                    <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                                        <span>-</span>
                                        <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
                                    </div>
                                    <div style={{width: 120}}>
                                    <span style={{width: '40%', height: 40}}>
                                        {s.timeStart}
                                    </span>
                                        <span>-</span>
                                        <span style={{width: '40%', height: 40}}>
                                        {s.timeEnd}
                                    </span>
                                    </div>
                                    <div style={{width: 120}}>
                                    <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                       {s.okStart}
                                   </span>
                                        <span>-</span>
                                        <span style={{width: '40%', height: 40, display: 'inline-block'}}>
                                        {s.okEnd}
                                    </span>
                                    </div>

                                    <div style={{width: 120}}>
                                   <span>
                                       {s.phone}
                                   </span>
                                    </div>
                                    <div style={{width: 70}}>
                                   <span>
                                       {s.attempt}
                                   </span>
                                    </div>
                                    <div style={{width: 120}} className="d-flex justify-content-center">
                                        <button onClick={() => handleDelete(s.id)} className="deleteBtn"
                                                style={{background: 'red'}}>
                                            {global.delete[+localStorage.getItem('atsLang')  || 1]}
                                        </button>
                                    </div>

                                </div>
                            ))}
                            <div className="d-flex flex-row text-center"
                                 style={{width: 'fit-content', margin: '10px 0'}}>

                                <div style={{width: 120}}>
                                    <select value={graphic.num}
                                            onChange={(ev) => handleTimeChange('num', ev.target.value)}
                                            style={{width: '100%', height: 40}}>
                                        <option
                                            value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                        {inAuto.map(k => (
                                            <option key={k.azang} value={k.azang}>{k.azang} ({k.comment})</option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{width: 120}}>
                                    <select value={graphic.weekStart}
                                            onChange={(ev) => handleTimeChange('weekStart', ev.target.value)}
                                            style={{width: '40%', height: 40}}>
                                        <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                        {weekDays.map(k => (
                                            <option key={k[+localStorage.getItem('atsLang')  || 1]}
                                                    value={k[+localStorage.getItem('atsLang')  || 1]}>{k[+localStorage.getItem('atsLang')  || 1]}</option>
                                        ))}
                                    </select>
                                    <span>-</span>
                                    <select value={graphic.weekEnd}
                                            onChange={(ev) => handleTimeChange('weekEnd', ev.target.value)}
                                            style={{width: '40%', height: 40}}>
                                        <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                        {weekDays.map(k => (
                                            <option key={k[+localStorage.getItem('atsLang')  || 1]}
                                                    value={k[+localStorage.getItem('atsLang')  || 1]}>{k[+localStorage.getItem('atsLang')  || 1]}</option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{width: 120}}>
                                    <input
                                        type="text"
                                        value={graphic.timeStart}
                                        onChange={(ev) => handleTime('timeStart',ev.target.value)}
                                        onBlur={() => handleBlur('timeStart')}
                                        placeholder="HH:mm"
                                        style={{
                                            width: '40%',
                                            textAlign: 'center',
                                            border: '1px solid',
                                            height: 35,
                                            fontSize: 14
                                        }}
                                    />
                                    <span>-</span>
                                    <input
                                        type="text"
                                        value={graphic.timeEnd}
                                        onChange={(ev) => handleTime('timeEnd',ev.target.value)}
                                        onBlur={() => handleBlur('timeEnd')}
                                        placeholder="HH:mm"
                                        style={{
                                            width: '40%',
                                            textAlign: 'center',
                                            border: '1px solid',
                                            height: 35,
                                            fontSize: 14
                                        }}
                                    />

                                </div>
                                <div style={{width: 120}}>
                                    <input
                                        className="ok"
                                        value={graphic.okStart}
                                        onChange={(ev) => handleTimeChange('okStart', ev.target.value)}
                                        style={{width: '40%', height: 40}}/>
                                    <span>-</span>
                                    <input
                                        className="ok"
                                        value={graphic.okEnd}
                                        onChange={(ev) => handleTimeChange('okEnd', ev.target.value)}
                                        style={{width: '40%', height: 40}}/>
                                </div>
                                <div style={{width: 120}}>
                                    <input className="ok"
                                           onChange={(ev) => handleTimeChange('phone', ev.target.value)}
                                           value={graphic.phone} style={{width: '90%', height: 40}}/>
                                </div>
                                <div style={{width: 70}}>
                                    <input className="ok"
                                           onChange={(ev) => handleTimeChange('attempt', ev.target.value)}
                                           value={graphic.attempt} style={{width: '90%', height: 40}}/>
                                </div>

                                <div style={{width: 120}} className="d-flex justify-content-center">
                                    <button onClick={handleAddGraphic} className="addBtn">
                                        {sip.add_btn[+localStorage.getItem('atsLang')  || 1]}
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex justify-content-between" style={{marginTop: 60}}>
                            {/*<button onClick={handleAdd} className="addBtn">*/}
                            {/*    {file.choose[+localStorage.getItem('atsLang')  || 1]}*/}
                            {/*</button>*/}
                            <button onClick={handleAdd} className="addBtnBig">
                                {global.edit[+localStorage.getItem('atsLang')  || 1]}
                            </button>
                            <button onClick={handleDeleteAll} className="addBtnBig" style={{background: 'red'}}>
                                {global.deleteAll[+localStorage.getItem('atsLang')  || 1]}
                            </button>
                        </div>
                    </Box>

                </div>

            </Wrapper>
        </>

    );
}

export default AutoCallEdit;
