import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {auto, global, input, lang, menu, profile} from "../translate";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import Api from "../Api";
import {toast} from "react-toastify";
import Cookies from "js-cookie";
import Account from "../helpers/Account";

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto'
};

function Profile(props) {
    const [form, setForm] = useState({
        phone: '',
        email: '',
        name: '',
        fio: '',
        login: '',
        pass: '',
        manager: '',
        manager_contact: '',
        m_email: '',
        m_name: '',
        manager_workDay: '',
        manager_workHour: '',
    })
    const [manager,setManager] = useState([])
    const [repass, setRepass] = useState('')

    const userData = useSelector((state) => state.users.userData)

    const dispatch = useDispatch()
    const handleWheel = useCallback((event) => {
        event.target.blur();
        event.preventDefault();
    },[]);
    useEffect(() => {
        if(userData.manager_id){
            setForm({...userData,password:'',manager:manager.find(m => +m?.id === +userData.manager_id),manager_id:manager.find(m => +m?.id === +userData.manager_id)?.id})
        }else{
            setForm({...userData,password:''})
        }
    }, [userData,manager])

    useEffect(() => {
        (async () => {
           try{
               const {data} = await Api.manager()
               setManager(data.manager)
           } catch (e) {

           }
        })()
    }, [])

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))

        if(name === 'manager_id'){
            setForm((prevState) => ({
                ...prevState,
                manager:manager.find(m => +m?.id === +ev)
            }))
        }
    }, [manager])

    const handleSubmit = useCallback(async (ev) => {
        try{
            ev.preventDefault()
            if(!Account.getUrsToken() && userData.phone !== form.phone){
                toast.error(lang[+localStorage.getItem('atsLang')] === 'en-AU' ? 'Only your manager can change the username' : lang[+localStorage.getItem('atsLang')] === 'ru' ? 'Изменить имя пользователя может только ваш менеджер' : 'Միայն ձեր մենեջերը կարող է փոխել օգտանունը')
                return
            }
            if(repass !== form.password){
                toast.error(lang[+localStorage.getItem('atsLang')] === 'en-AU' ? 'Passwords are not identical' : lang[+localStorage.getItem('atsLang')] === 'ru' ? 'Пароли не идентичны' : 'Գաղտնաբառերը նույնը չեն')
                return
            }

            if(!Account.getUrsToken() && form.name !== userData.name){
                toast.error(lang[+localStorage.getItem('atsLang')] === 'en-AU' ? 'Only your manager can change the username' : lang[+localStorage.getItem('atsLang')] === 'ru' ? 'Изменить имя пользователя может только ваш менеджер' : 'Միայն ձեր մենեջերը կարող է փոխել օգտանունը')
                return
            }
            await Api.editMember(form,Account.getUrsToken(),lang[+localStorage.getItem('atsLang')],location.pathname)
            toast.success('Success')
        }catch (e) {
            toast.error(e.response.data.message)
        }
    }, [form,userData,repass,lang])

    return (
        <>
            <Helmet>
                <title>
                    {menu.profile[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <h3>
                                {profile.profile[+localStorage.getItem('atsLang')  || 1]}
                            </h3>
                            <Link style={{cursor: 'pointer'}} to="/auto_call">
                                x
                            </Link>
                        </div>
                        <form onSubmit={handleSubmit} className="row" style={{margin: '20px 0'}}>
                            <p>{userData?.key}</p>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                                <p>{form.clientIp}</p>
                             <span>
                               {profile.num[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input onWheel={handleWheel} type="number" value={form.phone}
                                       onChange={(ev) => handleChange('phone', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.mail[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input type="mail" value={form.email}
                                       onChange={(ev) => handleChange('email', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.name[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input value={form.fio}
                                       onChange={(ev) => handleChange('fio', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.login[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input onWheel={handleWheel} type="number" value={form.name}
                                       onChange={(ev) => handleChange('name', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.pass[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input value={form.password}
                                       onChange={(ev) => handleChange('password', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.repass[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input value={repass}
                                       onChange={(ev) => setRepass(ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.manager[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <select disabled={!Cookies.get('viewer') && !Cookies.get('original')} className='manager_select' value={form.manager_id || 0}
                                        onChange={(ev) => handleChange('manager_id', ev.target.value)}>
                                    <option value="0">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {manager.length ?manager.map(m => (
                                        <option key={m.id} value={m.id}>{m.name}</option>
                                    )):null}
                                </select>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.manager_contact[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input readOnly value={form.manager?.phone}
                                       onChange={(ev) => handleChange('manager_contact', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.m_email[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input readOnly value={form.manager?.email}
                                       onChange={(ev) => handleChange('m_email', ev.target.value)}/>
                            </label><label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.m_name[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input readOnly value={form.manager?.name}
                                       onChange={(ev) => handleChange('m_name', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.manager_workDay[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input
                                    readOnly
                                    value={form.manager?.days}
                                    onChange={(ev) => handleChange('manager_workDay', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                             <span>
                               {profile.manager_workHour[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input readOnly
                                       value={form.manager?.hours}
                                       onChange={(ev) => handleChange('manager_workHour', ev.target.value)}/>
                            </label>
                            <div className="d-flex flex-row justify-content-center">
                                <button className="addBtn" onClick={handleSubmit}>
                                    {global.edit[+localStorage.getItem('atsLang')  || 1]}
                                </button>
                            </div>
                        </form>
                    </Box>

                </div>

            </Wrapper>
        </>

    );
}

export default Profile;
