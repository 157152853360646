import {call, put,select, takeLatest} from 'redux-saga/effects';
import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    URS_FAIL,
    URS_REQUEST,
    URS_SUCCESS,
    USER_CRM_REQUEST,
    USER_CRM_SUCCESS,
    USER_CRM_FAIL,
    USERS_FAIL,
    USERS_REQUEST,
    USERS_SUCCESS,
    PRICE_SUCCESS,
    PRICE_FAIL,
    PRICE_REQUEST,
    MONEY_REQUEST,
    MONEY_SUCCESS,
    MONEY_FAIL,
    OGG_REQUEST,
    OGG_SUCCESS,
    OGG_FAIL,
} from '../actions/users';
import Api from '../../Api';
import {toast} from "react-toastify";
import { navigateTo } from '../actions/navigateTo';
function* handleLoginRequest(action) {
    try {
        const {phone, password} = action.payload;
        const {data} = yield call(Api.login, {phone, password});
        yield put({
            type: LOGIN_SUCCESS,
            payload: {data},
        });
        yield put(navigateTo('/'));
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: LOGIN_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleUserProfileRequest() {
    try {
        const {data} = yield call(Api.profile);

        yield put({
            type: USERS_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        console.log(e)
        yield put({
            type: USERS_FAIL,
            payload: {error: e.response},
        });

    }
}
function* handleUrsProfileRequest() {
    try {
        const alreadyFetched = yield select((state) => state.users.fetchedAdmin);
        if (alreadyFetched) return;
        const {data} = yield call(Api.profileUrs);
        yield put({
            type: URS_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: URS_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handlePriceRequest() {
    try {
        const alreadyFetched = yield select((state) => state.users.fetchedPrice);
        if (alreadyFetched) return;
        const {data} = yield call(Api.prices);
        yield put({
            type: PRICE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: PRICE_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleMoneyRequest() {
    try {
        const alreadyFetched = yield select((state) => state.users.fetchedMoney);
        if (alreadyFetched) return;
        const {data} = yield call(Api.money);
        yield put({
            type: MONEY_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: MONEY_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleOGGRequest() {
    try {
        const alreadyFetched = yield select((state) => state.users.fetchedOGG);
        if (alreadyFetched) return;
        const {data} = yield call(Api.getText);
        yield put({
            type: OGG_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: OGG_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleUserCrmRequest() {
    try {
        const {data} = yield call(Api.userCrm);
        yield put({
            type: USER_CRM_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: USER_CRM_FAIL,
            payload: {error: e.response},
        });
    }
}


export default function* watcher() {
    yield takeLatest(LOGIN_REQUEST, handleLoginRequest);
    yield takeLatest(USERS_REQUEST, handleUserProfileRequest);
    yield takeLatest(URS_REQUEST, handleUrsProfileRequest);
    yield takeLatest(USER_CRM_REQUEST, handleUserCrmRequest);
    yield takeLatest(PRICE_REQUEST, handlePriceRequest);
    yield takeLatest(MONEY_REQUEST, handleMoneyRequest);
    yield takeLatest(OGG_REQUEST, handleOGGRequest);

}
