import React, {useState} from 'react';
import operator from '../assets/img/operator.png';
import AddAndEditSpySips from "./AddAndEditSpySips";
function SpyOperator({item}) {
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    return (
        <div className="sip_card">
            <div>
                <img src={operator} alt='operator'/>
                <p>{item.notes?`${item.notes} - `:''}{item.buddy} {item.issip?'(S)':''}</p>
                <p>{item?.included?item?.included?.context?`${item.included.context} - ${item.included.phone}`:`${item.included.phone}`:null}</p>

            </div>
            <div className="d-flex justify-content-between w-100">
                <button className="addBtnBig" onClick={() => setOpen(item.buddy)}>Spy</button>
                {item.isList?<button className="addBtnBig" onClick={() => {
                    setView(!view);
                    setOpen(item.buddy)
                }}>View</button>:null}
            </div>
            {open?<AddAndEditSpySips open={open} view={view} setOpen={setOpen} setView={setView}/>:null}
        </div>

    );
}

export default SpyOperator;