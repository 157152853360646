import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {global, input, lang, menu, sip} from "../translate";
import CloseIcon from "@mui/icons-material/Close";
import $ from "jquery";
import {DateTimePicker} from "react-datetime-picker";
import {useSelector} from "react-redux";
import Api from "../Api";
import {toast} from "react-toastify";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 500 ? 500 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    // maxHeight: 'auto',
    // overflow: 'auto'
};
function SetTimer({setForm, open,setOpen,ivr,ivrId}) {
    const [value, onChange] = useState(new Date(ivr.remind?ivr.remind.date:Date.now()));
    const profile = useSelector(state => state.users.ursData)
    const userData = useSelector((state) => state.users.userData)
    const handleAdd = useCallback(async () => {
        try {
            if(ivr.remind){
                await Api.editReminder(value, profile.phone || userData.phone, ivr.id, ivrId,ivr.remind.id)

            }else{
                const {data} = await Api.createReminder(value, profile.phone || userData.phone, ivr.id, ivrId,)
                setForm((prev) => {
                    const updatedTime = prev.time.map((item) => {
                        if (item.id === ivr.id) {
                            return { ...item, remind: data.data };
                        }
                        return item;
                    });
                    return { ...prev, time: updatedTime };
                })
            }
            toast.success('Success')
            setOpen(false)
        }catch (e) {
            console.log(e)
            toast.error('Something went wrong!')
        }
    }, [ivr, ivrId, value, profile, userData])
    const handleDelete = useCallback(async () => {
        try {
            // await Api.deleteReminder(ivr.remind.id)
            toast.success('Success')
            setForm((prev) => {
                const updatedTime = prev.time.map((item) => {
                    if (item.id === ivr.id) {
                        return { ...item, remind: null };
                    }
                    return item;
                });

                return { ...prev, time: updatedTime };
            })

            setOpen(false)
        }catch (e) {
            console.log(e)
            toast.error('Something went wrong!')
        }
    }, [ivr])
    useEffect(() => {
        (async () => {
            if(open && ivr.remind){
                const {data} = await Api.getReminder(ivr.remind.id)
                onChange(new Date(data.exist.date))
            }
        })()
    }, [open,ivr])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Set the reminder
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>
                    <div className="row" style={{margin: '30px 0'}}>
                        <label style={{margin:'20px 0'}} className="col-md-12 count">
                            <span>
                               <DateTimePicker className="dateTimePicker" onChange={onChange} value={value} />
                            </span>
                            {/*<input value={form.ip} onChange={(ev) => handleChange('ip', ev.target.value)}/>*/}
                        </label>
                    </div>
                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtn">
                            {global.accept[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                        {ivr.remind?<button style={{marginLeft:10}} onClick={handleDelete} className="deleteBtn">
                            {global.delete[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>:null}
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default SetTimer;