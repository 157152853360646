import React, {useCallback, useEffect, useState} from 'react';
import Api from "../Api";
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {menu, sip} from "../translate";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import {toast} from "react-toastify";
import operator from "../assets/img/operator.png";
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};
function AddAndEditSpySips({open, view, setView, setOpen}) {
    const [sipList, setSipList] = useState([])
    const [form, setForm] = useState({admin:open,list:[]})
    const handleChange = useCallback((ev) => {
        setForm((prevState) => ({
            ...prevState,
            list: ev
        }))
    }, [])
    const handleAdd = useCallback(async () => {
        try {
            await Api.apyOperatorPost(form)
            setOpen(false)
            setView(false)
            toast.success('Spy added successfully')
        }catch (e) {
            toast.error('Error adding spy')
        }
    }, [form])
    useEffect(() => {
        (async () => {
            const {data} = await Api.spySip()
            setSipList(data.sip)
        }) ()
    },[])
    useEffect(() => {
        (async () => {
            const {data} = await Api.apyOperatorGet(open)
            if(view){
                setForm((prevState) => ({
                    ...prevState,
                    list: data.sip
                }))
            }else{
                setForm((prevState) => ({
                    ...prevState,
                    list: data.sip.map(item => ({value: item.spy, label: `${item.sip.buddy}${item.sip.notes?` - ${item.sip.notes}`:''}`}))
                }))
            }

        }) ()
    },[open,view])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                {!view?
                    <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.spy[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            setOpen(false);
                            setView(false)
                        }}>
                            <CloseIcon/>
                        </span>
                    </div>

                    <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                        <Select
                            value={form.list}
                            isMulti
                            onChange={(ev) => handleChange(ev)}
                            styles={customStyles}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            name="colors"
                            options={sipList.map(item => ({value: item.buddy, label: `${item.buddy}${item.notes?` - ${item.notes}`:''}`}))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>

                    <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                        <button onClick={handleAdd} className="addBtnBig">
                            {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                        </button>
                    </div>
                </Box>:
                    <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.spy[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            setOpen(false);
                            setView(false)
                        }}>
                            <CloseIcon/>
                        </span>
                    </div>

                    <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                        {form.list.map(item => (
                            <div>
                                <p>{item.sip?.notes?`${item.sip?.notes} - `:''}{item.sip?.buddy} {item.sip?.issip?'(S)':''}</p>
                                <p>{item?.included?item?.included?.context?`${item.included.context} - ${item.included.phone}`:`${item.included.phone}`:null}</p>
                                <hr/>
                            </div>
                        ))}
                    </div>

                </Box>}
            </Fade>
        </Modal>
    );
}

export default AddAndEditSpySips;