import axios from 'axios';
import Account from './helpers/Account';
import Cookies from 'js-cookie'
import {REACT_APP_API_URL} from './config';
import {lang} from "./translate";
// const REACT_APP_API_URL = 'http://192.168.57.99:4001'
// const REACT_APP_API_URL = 'http://localhost:4001'
// const REACT_APP_API_URL = 'http://10.226.0.71:4001'
const api = axios.create({
    baseURL: REACT_APP_API_URL,
    'Access-Control-Allow-Origin': REACT_APP_API_URL
});

const urs = axios.create({
    baseURL: REACT_APP_API_URL,
    'Access-Control-Allow-Origin': REACT_APP_API_URL
});

const updateAndCreateApi = axios.create({
    // baseURL: 'http://10.227.0.86:4000',
    baseURL: REACT_APP_API_URL,
});


api.interceptors.request.use(
    (config) => {
        config.headers = {
            Authorization: `Bearer ${Account.getAdminToken()}`,
        };
        return config;
    },
    (error) => Promise.resolve({error}),
);


urs.interceptors.request.use(
    (config) => {
        config.headers = {
            Authorization: `Bearer ${Account.getUrsToken()}`,
        };
        return config;
    },
    (error) => Promise.resolve({error}),
);

updateAndCreateApi.interceptors.request.use(
    // eslint-disable-next-line no-unused-vars
    (config) => {
        config.headers = {
            Authorization: `Bearer ${Account.getAdminToken()}`,
            'Content-Type': 'multipart/form-data',
        };
        return config;
    },
    (error) => Promise.resolve({error}),
);
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};
let isRefreshing1 = false;
let failedQueue1 = [];

const processQueue1 = (error, token = null) => {
    failedQueue1.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue1 = [];
};

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                isRefreshing = true;

                try {
                    const {data} = await api.post('/users/refresh', {token: Account.getAdminRefreshToken()});

                    Account.setAdminToken(data);
                    isRefreshing = false;
                    processQueue(null, data.accessToken);

                    return api(originalRequest);
                } catch (e) {
                    Account.logoutAdmin();
                    window.location.reload()
                    processQueue(e, null);
                    return Promise.reject(error);
                } finally {
                    isRefreshing = false;
                }
            } else {
                return new Promise((resolve, reject) => {
                    failedQueue.push({resolve, reject});
                }).then((token) => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return api(originalRequest);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }
        } else if (error.response.status === 405) {
            if (window.location.pathname !== '/login') {
                window.location.pathname = '/login';
                Account.logoutAdmin();
            }
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);
urs.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (!isRefreshing1) {
                isRefreshing1 = true;

                try {
                    const {data} = await urs.post('/users/refresh_urs', {token: Account.getUrsRefreshToken()});

                    Account.setUrsAdminToken(data);
                    isRefreshing1 = false;
                    processQueue1(null, data.accessToken);

                    return urs(originalRequest);
                } catch (e) {
                    Account.logoutAdmin();
                    window.location.reload()
                    processQueue1(e, null);
                    return Promise.reject(error);
                } finally {
                    isRefreshing1 = false;
                }
            } else {
                return new Promise((resolve, reject) => {
                    failedQueue1.push({resolve, reject});
                }).then((token) => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return urs(originalRequest);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }
        } else if (error.response.status === 405) {
            if (window.location.pathname !== '/admin_login') {
                window.location.pathname = '/admin_login';
                Account.logoutAdmin();
            }
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

class Api {
    //-------SPY--------//
    static apyAdminGet() {
        return api.get(`/spy/admins`,);
    }
    static apyOperatorGet(admin) {
        return api.get(`/spy/operators?admin=${admin}`,);
    }
    static apyOperatorPost(form) {
        return api.post(`/spy/operators?admin=${form.admin}`,{form:form.list});
    }
    static spySip() {
        return api.get(`/spy/sip`,);
    }

    //-------CALLCENTER--------//

    static callCenterCreate(form) {
        return api.post(`/call_center/create`,{...form,urs:Account.getUrsToken()});
    }
    static callCenterSipCreate(form) {
        return api.post(`/call_center/crm_create`,{...form,urs:Account.getUrsToken()});
    }
    static callCenterSipDelete(form) {
        return api.post(`/call_center/crm_delete`,{...form,urs:Account.getUrsToken()});
    }
    static getCallCenter(sip) {
        return api.get(`/call_center/get`);
    }
    //-------RETAIL--------//
    static retail_domain(form) {
        return api.post(`/retail/create`,{...form,urs:Account.getUrsToken()});
    }
    static retailUsers() {
        return api.get(`/retail/users`,);
    }
    static retailCrmCreate(form) {
        return api.post(`/retail/crm_create`,{...form,token:Account.getUrsToken()});
    }
    static retailCrmDelete(id) {
        return api.post(`/retail/crm_delete?id=${id}`,{token:Account.getUrsToken()});
    }
    static retailDelete() {
        return api.post(`/retail/delete`,{token:Account.getUrsToken()});
    }
    static retailCrmEdit(form) {
        return api.put(`/retail/crm_edit`,{...form,token:Account.getUrsToken()});
    }
    //--------U-ON-------//
    static uon_domain(form) {
        return api.post(`/u-on/create`,{...form,urs:Account.getUrsToken()});
    }
    static uonUsers() {
        return api.get(`/u-on/users`,);
    }
    static uonCrmCreate(form) {
        return api.post(`/u-on/crm_create`,{...form,token:Account.getUrsToken()});
    }
    static uonCrmEdit(form) {
        return api.put(`/u-on/crm_edit`,{...form,token:Account.getUrsToken()});
    }
    static uonCrmDelete(id) {
        return api.post(`/u-on/crm_delete?id=${id}`,{token:Account.getUrsToken()});
    }
    static uonDelete() {
        return api.post(`/u-on/delete`,{token:Account.getUrsToken()});
    }
    //--------DRAG------//
    static drag() {
        return api.get(`/drag/data`);
    }
    static dragSingle(id,type) {
        return api.get(`/drag/single?id=${id}&type=${type}`);
    }
    static type(type) {
        return api.get(`/drag/type?type=${type}&token=${Account.getUrsToken()}`,);
    }
    static dragCreate(form) {
        return api.post(`/drag/create`,{...form,token:Account.getUrsToken()});
    }
    static dragCreateSip(form) {
        return api.post(`/drag/create_sip`,{...form,token:Account.getUrsToken()});
    }
    //-------BITRIX------//
    static audio(file) {
        return api.get(`/history/audio?file=${file}`);
    }
    static bitrix_domain(form) {
        return api.post(`/bitrix24/login?domain=${form}`,{token:Account.getAdminToken(),urs:Account.getUrsToken()});
    }
    static bitrix_delete() {
        return api.post(`/bitrix24/delete`,{urs:Account.getUrsToken()});
    }
    static bitrix_get() {
        return api.get(`/bitrix24/get`,);
    }
    static bitrix_admin() {
        return api.get(`/bitrix24/admin`,);
    }
    static bitrix_create(form) {
        return api.post(`/bitrix24/create`,form);
    }
    static bitrix_edit(form) {
        return api.post(`/bitrix24/edit`,form);
    }
    static bitrix_delete_crm(id) {
        return api.post(`/bitrix24/delete_crm?id=${id}`,);
    }

    //-------ZOHO------//
    static zohoCode(code,location) {
        return api.get(`/zoho/code?code=${code}&location=${location}`);
    }

    static getZohoUsers(page,limit) {
        return api.get(`/zoho/users?page=${page || 10}&per_page=${limit || 50}`);
    }
    static zohoDelete() {
        return api.post(`/zoho/delete`,{token:Account.getUrsToken()});
    }
    static zohoCrmCreate(form) {
        return api.post(`/zoho/crm`, {...form,token:Account.getUrsToken()});
    }
    static zohoCrmEdit(form) {
        return api.put(`/zoho/crm`,{...form,token:Account.getUrsToken()});
    }
    static zohoCrmDelete(id) {
        return api.post(`/zoho/crm_delete?id=${id}`,{token:Account.getUrsToken()});
    }
    //------AMO----------//
    static amoKey() {
        return api.get(`/amo/key`);
    }
    static amoUsers() {
        return api.get(`/amo/users`);
    }
    static amoDelete() {
        return api.post(`/amo/delete`,);
    }
    static amoCrmCreate(form) {
        return api.post(`/amo/crm`, {...form,token:Account.getUrsToken()});
    }
    static amoCrmEdit(form) {
        return api.put(`/amo/crm`,{...form,token:Account.getUrsToken()});
    }
    static amoCrmConfigs(form,sip) {
        return api.put(`/amo/configs`,{config:form,sip,token:Account.getUrsToken()});
    }
    static amoCrmDelete(id) {
        return api.post(`/amo/crm_delete?id=${id}`,{token:Account.getUrsToken()});
    }
    //--------CUSTOM-------//
    static getCrmSip() {
        return api.get(`/custom_crm/sip`);
    }
    static getCrm() {
        return api.get(`/custom_crm/get`);
    }
    static createCustomCrm(current) {
        return api.post(`/custom_crm/create`,{...current,token:Account.getUrsToken()});
    }
    static editCustomCrm(form) {
        return api.put(`/custom_crm/edit`, {...form,token:Account.getUrsToken()});
    }
    static deleteCustomCrm(id) {
        return api.post(`/custom_crm/delete`, {id,token:Account.getUrsToken()});
    }

    //--------PIPEDRIVE--------//

    static pipedrive_domain(form) {
        return api.post(`/pipedrive/confirm`,{domain:form,urs:Account.getUrsToken()});
    }
    static pipedrive_delete() {
        return api.post(`/pipedrive/delete`,{urs:Account.getUrsToken()});
    }
    static pipedriveUsers() {
        return api.get(`/pipedrive/users`);
    }
    static pipedriveCrmCreate(form) {
        return api.post(`/pipedrive/crm`,{...form,token:Account.getUrsToken()});
    }

    static pipedriveCrmEdit(form) {
        return api.put(`/pipedrive/crm`,{...form,token:Account.getUrsToken()});
    }

    static pipedriveCrmDelete(id) {
        return api.post(`/pipedrive/crm_delete?id=${id}`,{token:Account.getUrsToken()});
    }

    //-------MONDAY-------//

    static monday_domain(form) {
        return api.post(`/monday/confirm?domain=${form}`,{domain:form,urs:Account.getUrsToken()});
    }
    static mondayUsers() {
        return api.get(`/monday/users`);
    }
    static monday_delete() {
        return api.post(`/monday/delete`,{urs:Account.getUrsToken()});
    }
    static mondayCrmCreate(form) {
        return api.post(`/monday/crm`, {...form,token:Account.getUrsToken()});
    }
    static mondayCrmEdit(form) {
        return api.put(`/monday/crm`,{...form,token:Account.getUrsToken()});
    }
    static mondayCrmDelete(id) {
        return api.post(`/monday/crm_delete?id=${id}`,{token:Account.getUrsToken()});
    }

    //--------ALTEGIO -------//
    static altegio_domain(crm_token) {
        return api.post(`/altegio/create`,{crm_token:crm_token,token:Account.getUrsToken()});
    }
    static altegio_create(sip) {
        return api.post(`/altegio/crm`,{sip,token:Account.getUrsToken()});
    }
    static altegio_get() {
        return api.get(`/altegio/get`);
    }
    static altegio_check() {
        return api.get(`/altegio/check`);
    }
    static altegio_crmDelete(id) {
        return api.post(`/altegio/crm_delete`,{id,token:Account.getUrsToken()});
    }
    static deleteAltegio() {
        return api.post(`/altegio/delete`,{token:Account.getUrsToken()});
    }
    //-------USERS------//

    static login(form) {
        return api.post('/users/login', form);
    }
    static getText() {
        return api.get('/ogg');
    }
    static userCrm() {
        return api.get('/users/crm');
    }

    static globalSearch(search) {
        return api.get(`/users/global_search?search=${search}&token=${Account.getUrsToken()}`);
    }
    static online(search) {
        return api.get(`/users/online`);
    }
    static ursOnline() {
        return urs.get(`/users/urs_online`);
    }
    static reset_pass(phone) {
        return urs.post(`/users/reset_pass`,{token:Account.getUrsToken(),phone});
    }
    static IP() {
        return api.get('/users/user_ip');
    }
    static blockedIp(search) {
        return api.get(`/ip/blocked_ip?search=${search}&token=${Account.getUrsToken()}`);
    }


    static register(phone,link) {
        return urs.post(`/users/register?token=${Account.getUrsToken()}&link=${link}`, {phone});
    }

    static registerUser(phone) {
        return urs.post(`/users/register_user`, {phone});
    }

    static registerUserVerify(phone, code) {
        return urs.post(`/users/register_user_verify?type=${process.env.REACT_APP_SERVER === 'ATS'?'ats':''}`, {phone, code});
    }

    static adminLogin(form) {
        return api.post('/users/admin_login', form);
    }

    static confirm(form) {
        return api.post('/users/confirm', form);
    }

    static editMember(form, token, lang,link) {
        return api.post(`/users/edit?token=${token}&lang=${lang}&link=${link}`, form);
    }

    static profile() {
        return api.get('/users/profile');
    }

    static profileUrs() {
        return urs.get('/users/profile_urs');
    }

    static manager() {
        return api.get('/users/manager');
    }

    static members(page, search, billing,testmode,isblocked,matrix,gumar) {
        return urs.get(`/users/members?page=${page}${search ? '&search=' + search : ''}${billing? '&billing='+billing : ''}${testmode?'&testmode='+testmode:''}${isblocked?'&isblocked='+isblocked:''}${matrix?'&matrix='+matrix:''}${gumar?'&gumar='+gumar:''}`);
    }

    static comment(id) {
        return urs.get(`/users/comment?member_id=${id}`);
    }

    static create_comment(form,link) {
        return urs.post(`/users/create_comment?link=${link}`, form);
    }

    static blocked(id, blocked) {
        return urs.post(`/users/blocked?blocked=${blocked}&member_id=${id}`);
    }

    static loginAsUser(id, original, key) {
        return urs.post(`/users/login_as_member`, {id, original, key});
    }

    static billing(form,link) {
        return urs.post(`/users/billing?link=${link}`, form);
    }
    static getBilling() {
        return urs.get(`/users/get_billing`);
    }

    static test_mode(id, test,link) {
        return urs.post(`/users/test_mode?member_id=${id}&test=${test}&link=${link}`,);
    }
    //-------IP ADDRESS-----/
    static createIp(ip,limit,page,search,sort,sortBy) {
        return urs.post(`/ip/create?link=${location.pathname}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`,{ip,token:Account.getAdminToken(),urs:Account.getUrsToken()});
    }
    static getIp(limit,page,search,sort,sortBy) {
        return urs.get(`/ip/get?link=${location.pathname}&token=${Account.getAdminToken()}&urs=${Account.getUrsToken()}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`);
    }
    static deleteIp(ip) {
        return urs.post(`/ip/delete?link=${location.pathname}&id=${ip}`,{token:Account.getAdminToken(),urs:Account.getUrsToken()});
    }
    //-------SIP------//
    static sip(limit, page, search, sort, sortBy,) {
        return api.get(`/sip/get?limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`);
    }
    static sip_ip() {
        return api.get(`/sip/sip_ip`);
    }

    static sipSingle(buddy) {
        const parsedBuddy = Number(buddy);

        if (!isNaN(parsedBuddy)) {
            return api.get(`/sip/single?buddy=${parsedBuddy}`);
        } else {
            console.error('Invalid buddy value:', buddy);

        }
    }

    static getCRM() {
        return api.get(`/sip/crm`);
    }

    static sipEdit(buddy, form, limit, page, search,link, lang,sort,sortBy) {
        return api.post(`/sip/edit?buddy=${buddy}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}&lang=${lang}&link=${link}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`, {...form,token:Account.getUrsToken()});
    }

    static sipDelete(form) {
        return api.post(`/sip/delete?link=${location.pathname}`, form);
    }

    static sipTelegramCreate(form) {
        return api.post(`/sip/telegram?link=${location.pathname}/create_telegram`, {...form,urs:Account.getUrsToken()});
    }
    static sipGlobalChange(arr,form) {
        return api.post(`/sip/update_global?link=${location.pathname}`, {...form,arr,urs:Account.getUrsToken()});
    }

    static sipTelegramDelete(buddy) {
        return api.post(`/sip/telegram_delete?buddy=${buddy}&link=${location.pathname}/delete_telegram`,{urs:Account.getUrsToken()});
    }

    static sipCreate(buddy, token, issip, limit, page, search, lang,sort, sortBy) {
        return api.post(`/sip/create?count=${buddy}&issip=${issip}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}&lang=${lang}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}&link=${location.pathname}/sip_create`,{token});
    }

    static crmCreate(form) {
        return api.post(`/sip/create_crm?link=${location.pathname}/crm_create`, {...form,urs:Account.getUrsToken()});
    }

    static crmEdit(form) {
        return api.post(`/sip/edit_crm?link=${location.pathname}/crm_edit`, {...form,urs:Account.getUrsToken()});
    }

    static crmDelete(id, buddy) {
        return api.post(`/sip/delete_crm?id=${id}&buddy=${buddy}&link=${location.pathname}/delete_crm`,{urs:Account.getUrsToken()});
    }

    static crmSingle(id) {
        return api.get(`/sip/single_crm?id=${id}`);
    }


    //-------ALLOWED PHONE ------//
    static allowed(limit, page, search, sort, sortBy) {
        return api.get(`/allowed/get?limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`);
    }
    static allowedUpload(form,limit, page, search, sort, sortBy) {
        return updateAndCreateApi.post(`/allowed/upload?link=${location.pathname}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`, {exel:form,token:Account.getUrsToken()});
    }

    static provider() {
        return api.get(`/allowed/provider`);
    }

    static allowedCreate(limit, page, search, form, sort, sortBy) {
        return api.post(`/allowed/create?limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}&link=${location.pathname}/create`, {...form,token:Account.getUrsToken()});
    }

    static allowedEdit(limit, page, search, form, sort, sortBy) {
        return api.post(`/allowed/edit?limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}&link=${location.pathname}/edit`, {...form,urs:Account.getUrsToken()});
    }

    static allowedSingle(id) {
        return api.get(`/allowed/single?id=${id}`);
    }

    static allowedDelete(id) {
        return api.post(`/allowed/delete?id=${id}&link=${location.pathname}/delete`,{urs:Account.getUrsToken()});
    }
    static allowedDeleteAll(id) {
        return api.post(`/allowed/delete_with?id=${id}&link=${location.pathname}/delete`,{urs:Account.getUrsToken()});
    }

    //-------SHORT------//
    static short(limit, page, search, sort, sortBy) {
        return api.get(`/virtual/get?limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`);
    }

    static shortCreate(limit, page, search, form,sort,sortBy) {
        return api.post(`/virtual/create?limit=${limit || 10}&page=${page || 1}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}&search=${search || ''}&lang=${lang[+localStorage.getItem('atsLang')]}&link=${location.pathname}/create`, {...form,urs:Account.getUrsToken()});
    }

    static shortEdit(limit, page, search, id, form,sort,sortBy) {
        return api.post(`/virtual/edit?id=${id}&limit=${limit || 10}&page=${page || 1}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}&search=${search || ''}&lang=${lang[+localStorage.getItem('atsLang')]}&link=${location.pathname}/edit`, {...form,urs:Account.getUrsToken()});
    }

    static shortSip() {
        return api.get(`/virtual/sip`);
    }

    static shortSingle(id) {
        return api.get(`/virtual/single?id=${id}`);
    }

    static shortDelete(id) {
        return api.post(`/virtual/delete?id=${id}&link=${location.pathname}/delete`,{urs:Account.getUrsToken()});
    }

    //-------SHORTFunction------//
    static shortFunctionGet() {
        return api.get(`/api/get`);
    }

    static shortFunctionEdit(form) {
        return api.post(`/api/edit?link=${location.pathname}&urs=${Account.getUrsToken()}`, form);
    }

    //-------IVR------//

    static IVR(limit, page, search, sort, sortBy) {
        return api.get(`/ivr/get?lang=${lang[+localStorage.getItem('atsLang')]}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}${sort ? '&sort=' + sort : ''}${sortBy ? '&sortBy=' + sortBy : ''}`);
    }

    static singleIvr(id) {
        return api.get(`/ivr/single?id=${id}`);
    }
    static getReminder(id) {
        return api.get(`/ivr/reminder?id=${id}`);
    }
    static deleteReminder(id) {
        return api.delete(`/ivr/reminder?id=${id}&urs=${Account.getUrsToken()}`);
    }
    static editReminder(time, phone, ivr, ivrId,id) {
        return api.put(`/ivr/reminder`,{id,time, phone, ivr, ivrId, urs:Account.getUrsToken()});
    }
    static createReminder(time, phone, ivr, ivrId) {
        return api.post(`/ivr/reminder`,{time, phone, ivr, ivrId, urs:Account.getUrsToken()});
    }

    static createIvr(limit, page, search, form) {
        return api.post(`/ivr/create?lang=${lang[+localStorage.getItem('atsLang')]}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}`, {urs:Account.getUrsToken(),...form});
    }

    static editIvr(limit, page, search, id, form) {
        return api.post(`/ivr/edit?id=${id}&lang=${lang[+localStorage.getItem('atsLang')]}&limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}`, {urs:Account.getUrsToken(),...form});
    }

    static deleteIvr(id) {
        return api.post(`/ivr/delete?id=${id}&lang=${lang[+localStorage.getItem('atsLang')]}&link=${location.pathname}`,{urs:Account.getUrsToken()});
    }

    //----------DID----------//

    static getCreateDid() {
        return api.get(`/did/getDidPear?token=${Account.getUrsToken()}`);
    }

    static createDid(form,limit,page) {
        return api.post(`/did/create?link=${location.pathname}&page=${page || 1}&limit=${limit || 10}`, {...form,token:Account.getUrsToken()});
    }

    static editDid(form,provider,limit,page) {
        return api.post(`/did/edit?link=${location.pathname}&provider=${provider}&page=${page || 1}&limit=${limit || 10}`, {...form,token:Account.getUrsToken()});
    }

    static deleteDid(phone,provider) {
        return api.post(`/did/delete?phone=${phone}&link=${location.pathname}&provider=${provider}`,{token:Account.getUrsToken()});
    }

    static telegReg(phone) {
        return api.post(`/did/telegram_provider_create?phone=${phone}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }

    static telegRegVerify(phone, code, session_id, storage_id) {
        return api.post(`/did/telegram_provider_verify?phone=${phone}&code=${code}&session_id=${session_id}&storage_id=${storage_id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }

    static telegRegVerifyEmail(phone, code, session_id, storage_id) {
        return api.post(`/did/telegram_provider_verify_email?phone=${phone}&code=${code}&session_id=${session_id}&storage_id=${storage_id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }

    static telegRegDelete(username) {
        return api.post(`/did/telegram_provider_delete?username=${username}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }


    static getDid(search,limit,page) {
        return api.get(`/did/get?search=${search || ''}&page=${page || 1}&limit=${limit || 10}`);
    }

    static singleDid(phone,provider) {
        return api.get(`/did/single?phone=${phone}&provider=${provider}`);
    }


    //---------OTHER SIP ----------//

    static getProvider(search,limit,page) {
        return api.get(`/other_sip/get?search=${search || ''}&page=${page || 1}&limit=${limit || 10}`);
    }

    static getSingleProvider(id) {
        return api.get(`/other_sip/single?id=${id}`);
    }

    static createProvider(form,limit,page) {
        return api.post(`/other_sip/create?link=${location.pathname}&page=${page || 1}&limit=${limit || 10}`, {...form,token:Account.getUrsToken()});
    }

    static editProvider(form,limit,page) {
        return api.post(`/other_sip/edit?link=${location.pathname}&page=${page || 1}&limit=${limit || 10}`, {...form,token:Account.getUrsToken()});
    }

    static deleteProvider(id) {
        return api.post(`/other_sip/delete?id=${id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }


    //-------FORWARD-------//
    static createForward(limit, page, search, form,) {
        return api.post(`/forward/create?limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static editForward(limit, page, search, form,) {
        return api.post(`/forward/edit?limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static deleteForward(id) {
        return api.post(`/forward/delete?id=${id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }

    static getSelect(type) {
        return api.get(`/forward/get_select?type=${type}`);
    }

    static getSingle(id) {
        return api.get(`/forward/single?id=${id}`);
    }

    static getForward(limit, page, search) {
        return api.get(`/forward/get?limit=${limit || 10}&page=${page || 1}&search=${search || ''}`);
    }


    //-------CALL ROUT-------//
    static getCreateSelectForward() {
        return api.get(`/call_rout/get_select`);
    }

    static getSingleRoute(id) {
        return urs.get(`/call_rout/single?id=${id}`);
    }

    static getCallRout(limit, page, search) {
        return api.get(`/call_rout/get?limit=${limit || 10}&page=${page || 1}&search=${search || ''}&token=${Account.getUrsToken()}`);
    }

    static createCallRout(limit, page, search, form) {
        return api.post(`/call_rout/create?limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static editCallRout(limit, page, search, id, form) {
        return api.post(`/call_rout/edit?limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}&id=${id}`, {...form,token:Account.getUrsToken()});
    }

    static deleteCallRout(id) {
        return urs.post(`/call_rout/delete?id=${id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }


    //-------BLOCKED-------//

    static getBlocked(limit, page, search) {
        return api.get(`/blocked/get?limit=${limit || 10}&page=${page || 1}&search=${search || ''}`);
    }

    static createBlocked(limit, page, search, form) {
        return api.post(`/blocked/create?limit=${limit || 10}&page=${page || 1}&search=${search || ''}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static deleteBlocked(id) {
        return api.post(`/blocked/delete?id=${id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }


    //-------FILE-------//

    static fileCreate(form) {
        return updateAndCreateApi.post(`/files/create?link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static fileGet() {
        return api.get(`/files/get`);
    }
    static download(uid) {
        return api.get(`/files/download?uid=${uid}`);
    }

    static fileDelete(id) {
        return api.post(`/files/delete?id=${id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }
    static fileEdit(id,musicHold) {
        return api.post(`/files/update?id=${id}&musicHold=${musicHold}`,{token:Account.getUrsToken()});
    }

    static fileIdict(form) {
        return api.post(`/files/idict?link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static fileYandex(form) {
        return api.post(`/files/yandex?link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    //-------CL GROUP-------//
    static clGet() {
        return api.get(`/users/clgroup`);
    }

    static money() {
        return api.get(`/users/money`);
    }
    static prices() {
        return api.get(`/users/prices`);
    }

    static moneyHistory() {
        return api.get(`/users/money_history`);
    }

    //------AUTO CALL--------//
    static getInAuto() {
        return api.get(`/auto_call/get_in`);
    }

    static getAuto() {
        return api.get(`/auto_call/get`);
    }

    static createAuto(form) {
        return api.post(`/auto_call/create?lang=${lang[+localStorage.getItem('atsLang')]}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static editAuto(form, id) {
        return api.post(`/auto_call/edit?lang=${lang[+localStorage.getItem('atsLang')]}&id=${id}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }
    static editAutoStatus(id,form,) {
        return api.post(`/auto_call/edit_status?lang=${lang[+localStorage.getItem('atsLang')]}&id=${id}&link=${location.pathname}`, {...form,token:Account.getUrsToken()});
    }

    static singleAuto(id) {
        return api.get(`/auto_call/single?lang=${lang[+localStorage.getItem('atsLang')]}&id=${id}`);
    }

    static deleteItem(id) {
        return api.post(`/auto_call/delete_item?lang=${lang[+localStorage.getItem('atsLang')]}&id=${id}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }

    static deleteAuto(id, grp) {
        return api.post(`/auto_call/delete?lang=${lang[+localStorage.getItem('atsLang')]}&id=${id}${grp ? '&grp=' + grp : ''}&link=${location.pathname}`,{token:Account.getUrsToken()});
    }

    //------HISTORY--------//
    static history(row, page, filter) {
        return api.get(`/history/get?page=${page || 1}&limit=${row || 10}${filter?.disposition ? '&disposition=' + filter.disposition : ''}${filter.number ? '&number=' + filter.number : ''}${filter.startTime ? '&startTime=' + new Date(filter.startTime).toISOString() : ''}${filter.endTime ? '&endTime=' + new Date(filter.endTime).toISOString() : ''}${filter.status ? '&status=' + filter.status : ""}${filter.line ? '&line=' + filter.line : ''}${filter.period ? '&period=' + filter.period : ''}`);
    }

    static graphic(type,isTrue,filter) {
        return api.get(`/history/graphic?type=${type}&isTrue=${isTrue}${filter.timeStartStart ? '&timeStartStart=' + filter.timeStartStart : ''}${filter.timeEndEnd ? '&timeEndEnd=' + filter.timeEndEnd : ''}`);
    }

    static duration(filter) {
        return api.get(`/history/duration?page=1${filter?.disposition ? '&disposition=' + filter.disposition : ''}${filter.number ? '&number=' + filter.number : ''}${filter.startTime ? '&startTime=' + new Date(filter.startTime).toISOString() : ''}${filter.endTime ? '&endTime=' + new Date(filter.endTime).toISOString() : ''}${filter.status ? '&status=' + filter.status : ""}${filter.line ? '&line=' + filter.line : ''}${filter.period ? '&period=' + filter.period : ''}`);
    }

    static getAllowed() {
        return api.get(`/history/allowed`);
    }


    //---------REPORT----------//
    static getReport(nm) {
        return urs.get(`/users/month_report${nm ? '?nm=1' : ''}${nm ? '&' : '?'}link=${location.pathname}`);
    }

    static getVoip(start, end) {
        return urs.get(`/users/voip?start=${start}&end=${end}&link=${location.pathname}`);
    }

    static getUsed() {
        return urs.get(`/users/used?link=${location.pathname}`);
    }

    //------LOGS-------//

    static getLogs(limit,page,filter) {
        return urs.get(`/logs/get?id=${Cookies.get('viewer')}&page=${page || 1}&limit=${limit || 10}${filter?.startTime ? '&start=' + filter.startTime : ''}${filter?.endTime ? '&end=' + filter.endTime : ''}${filter.type ? '&type=' + filter.type : ''}${filter.method ? '&method=' + filter.method : ""}${filter.isAdmin ? '&isAdmin=' + filter.isAdmin : ''}`);
    }
    static statistic(type) {
        return urs.get(`/logs/statistic?id=${Cookies.get('viewer')}&type=${type}`);
    }
    static getSingleLogs(id) {
        return urs.get(`/logs/single?id=${id}`);
    }
}

export default Api;
