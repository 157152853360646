import React, {useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import SpyOperator from "../components/SpyOperator";

function SpyServer(props) {
    const [sip,setSip] = useState([])
    useEffect(() => {
        (async () => {
            const {data} = await Api.apyAdminGet()
            setSip(data.sip)
        })()
    }, [])
    return (
        <Wrapper>
            <div className="d-flex flex-wrap">
                {sip.map((item,index) => (<SpyOperator item={item}/>))}
            </div>
        </Wrapper>
    );
}

export default SpyServer;