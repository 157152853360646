import {
    MONEY_FAIL,
    MONEY_SUCCESS, OGG_FAIL, OGG_SUCCESS, PRICE_FAIL, PRICE_SUCCESS,
    URS_FAIL,
    URS_REQUEST,
    URS_SUCCESS,
    USER_CRM_SUCCESS,
    USERS_FAIL,
    USERS_REQUEST,
    USERS_SUCCESS,
} from '../actions/users';


const initialState = {
    userStatus: 'request',
    userData: {},
    crmData: [],
    countData: {},
    ursData: {},
    fetchedPrice: false,
    prices: [],
    ogg: [],
    fetchedAdmin: false,
    fetchedMoney: false,
    fetchedOGG: false,
    money: [],
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case USERS_REQUEST: {
            return {
                ...state,
                userStatus: 'request',
            };
        }
        case USERS_SUCCESS: {
            return {
                ...state,
                userStatus: 'success',
                userData: {...action.payload.data.user,clientIp:action.payload.data.clientIp},
                countData: action.payload.data.count,
            };
        }
        case USER_CRM_SUCCESS: {
            return {
                ...state,
                crmData:action.payload.data.crm
            };
        }
        case USERS_FAIL: {
            return {
                ...state,
                userStatus: 'fail',
                userData: action.payload.data,
                fetched:false,
            };
        }
        case URS_REQUEST: {
            return {
                ...state,
                userStatus: 'request',
            };
        }
        case URS_SUCCESS: {
            return {
                ...state,
                userStatus: 'success',
                ursData: action.payload.data.user,
                fetchedAdmin:true
            };
        }
        case URS_FAIL: {
            return {
                ...state,
                userStatus: 'fail',
                ursData: action.payload.data,
                fetchedAdmin:false
            };
        }
        case MONEY_SUCCESS: {
            return {
                ...state,
                money: action.payload.data,
                fetchedMoney:true
            };
        }
        case MONEY_FAIL: {
            return {
                ...state,
                fetchedMoney:false
            };
        }
        case PRICE_SUCCESS: {
            return {
                ...state,
                prices: action.payload.data.data,
                fetchedPrice:true
            };
        }
        case PRICE_FAIL: {
            return {
                ...state,
                fetchedPrice:false
            };
        }

        case OGG_SUCCESS: {
            return {
                ...state,
                ogg: Object.values(action.payload.data.rows[0]),
                fetchedOGG:true
            };
        }
        case OGG_FAIL: {
            return {
                ...state,
                fetchedOGG:false
            };
        }


        default: {
            return {
                ...state,
            };
        }
    }
}
