import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {crm, global, sip, input, lang} from "../translate";
import CreateAndEditCrm from "./CreateAndEditCrm";
import {useDispatch, useSelector} from "react-redux";
import {crmSingleRequest, sipEditRequest, sipSingleRequest} from "../store/actions/sip";
import {toast} from "react-toastify";
import {crmRequest} from "../store/actions/crm";
import _ from 'lodash'
import Api from "../Api";
import TelegramIcon from '@mui/icons-material/Telegram';
import Telegram from "./Telegram";
import TelegramDelete from "./TelegramDelete";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import Cookies from "js-cookie";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
function EditSip({open, setOpen,code}) {
    const [checked, setChecked] = useState(true)
    const [telegram, setTelegram] = useState({pass:'',buddy:open,phone:''})
    const [edit, setEdit] = useState({
        pass: '',
        rec: 0,
        rate: 0,
        isspyadmin: 0,
        reenter: '',
        out: '',
        mob: 0,
        fixed: 0,
        inter: 0,
        payed: 0,
        isSip: 0,
        history: 0,
        note: '',
        ip: '',
        groupId:''
    })
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const crmData = useSelector(state => state.crm.crmData)
    const single = useSelector(state => state.sip.sipSingleData)
    const sipTelegramData = useSelector(state => state.sip.sipTelegramData)
    const sipFix = useSelector(state => state.sip.sipFix)
    const [memberCrm,setMemCrm] = useState([])
    const [newCrm,setNewCrm] = useState({id:'',buddy:open})
    const [isTelegram,setIsTelegram] = useState(0)
    const [forTelegram,setForTelegram] = useState(0)
    const [editCrm,setEditNewCrm] = useState({id:0,type:''})
    const dispatch = useDispatch()
    const handleEditChange = useCallback((name, ev) => {
        setEdit((prev) => ({
            ...prev,
            [name]: ev
        }))
    }, [])
    const handleWheel = useCallback((event) => {
        event.target.blur();
        event.preventDefault();
    },[]);
    const handleEdit = useCallback(async () => {
        if(edit.pass !== edit.reenter){
            toast.error(lang[+localStorage.getItem('atsLang')] === 'en-AU' ? 'Passwords are not identical' : lang[+localStorage.getItem('atsLang')] === 'ru' ? 'Пароли не идентичны' : 'Գաղտնաբառերը նույնը չեն')
            return
        }
        if(edit.rec === 1){
            toast.error('Record count should be greater than 1')
            return
        }
        await dispatch(sipEditRequest(open,edit,Cookies.get("sip") ||query.limit || 10,query.page,query.search,window.location.pathname,query.sortBy || '', query.sortOrder || ''))
        setOpen(0)
    }, [edit,checked,lang,open,location.search])

    useEffect(() => {
        (async () => {
           await dispatch(sipSingleRequest(open))
           await dispatch(crmRequest())
        })()
    }, [open])

    useEffect(() => {
        setEdit({
            pass: '',
            rec: +single.rec,
            reenter: '',
            out: single.sipdid,
            isspyadmin: single.isspyadmin,
            rate: single.rate || 0,
            mob: single?.codetypeResult?.find(c => c.id === 1) ? 1 : 0,
            fixed: single?.codetypeResult?.find(c => c.id === 2) ? 2 : 0,
            inter: single?.codetypeResult?.find(c => c.id === 3) ? 3 : 0,
            payed: single?.codetypeResult?.find(c => c.id === 4) ? 4 : 0,
            isSip: single.issip,
            history: single.oprec || 2,
            note: single.notes || '',
            ip: single?.sip_buddy?.uagent || '',
            groupId: single?.pickupgroup?.split('_')[1] || '',
        })
        setMemCrm(single.crm)
        setTelegram({pass:'',buddy:open,phone:sipTelegramData?.phone_number})
        setForTelegram(sipTelegramData?.phone_number)
    }, [single,sipTelegramData,open])


    const handleDelete = useCallback(async (id) => {
        try{
            const {data} = await Api.crmDelete(id,open)
            setMemCrm(data.crm)
        }catch (e) {

        }
    },[open])
    return (
       <>

           <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={!!open}
               onClose={() => setOpen(0)}
               closeAfterTransition
               slots={{backdrop: Backdrop}}
               slotProps={{
                   backdrop: {
                       timeout: 500,
                   },
               }}
           >
               <Fade in={!!open}>
                   <Box sx={style}>
                       <div className="d-flex flex-row justify-content-between">
                           <Typography id="transition-modal-title" variant="h6" component="h2">
                               {sip.edit[+localStorage.getItem('atsLang')  || 1 ]}
                           </Typography>
                           <span style={{cursor: 'pointer'}} onClick={() => setOpen(0)}>
                            <CloseIcon/>
                        </span>
                       </div>
                       <div id="transition-modal-description sip_content" >
                           <div className="row">
                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <span>{sip.edit[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <input style={{color: '#CCC'}} readOnly onChange={() => null} value={open.toString() || ''}/>
                               </label>
                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <span>{sip.group[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <input onWheel={handleWheel} type="number" value={edit.groupId || ''} onChange={(ev) => handleEditChange('groupId', ev.target.value)}/>
                               </label>

                               <label className="col-md-6 count " style={{margin: '20px 0'}}>
                                <span
                                    className="d-flex flex-row align-items-center justify-content-between">{sip.call_rec[+localStorage.getItem('atsLang')  || 1 ]}
                                    <input

                                    style={{width: 10}} checked={checked}
                                    onChange={(ev) => {
                                        if (!ev.target.checked){
                                            handleEditChange('rec', 0)
                                        }
                                        setChecked(ev.target.checked)
                                    }} type="checkbox"/></span>
                                   <input disabled={!checked} value={edit.rec||0}
                                          onChange={(ev) => handleEditChange('rec', checked ? +ev.target.value : 0)}
                                          type="number"
                                          onWheel={handleWheel}
                                   />


                               </label>
                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <span>{sip.pass[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <input value={edit.pass || ''} onChange={(ev) => handleEditChange('pass', ev.target.value)}/>
                               </label>

                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <span>{sip.out[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <Select
                                       styles={customStyles}
                                       menuPlacement="auto"
                                       menuPosition="fixed"
                                       onChange={(ev) => handleEditChange('out', ev.value)}
                                       menuPortalTarget={document.body}
                                       className="basic-single"
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       value={{value:edit.out,label:edit.out,}}
                                       name="color"
                                       options={[{value:'',label:"Select"},...sipFix.map(k => (
                                           {value: k.name, label: `${k.name}`}
                                       ))]}
                                   />
                               </label>
                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <span>{sip.reenter[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <input value={edit.reenter || ''}
                                          onChange={(ev) => handleEditChange('reenter', ev.target.value)}/>
                               </label>
                               <div className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.mobile[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.mob || 0}
                                              onChange={(ev) => handleEditChange('mob', ev.target.checked ? 1 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.fix_num[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.fixed || 0}
                                              onChange={(ev) => handleEditChange('fixed', ev.target.checked ? 2 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.international[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.inter || 0}
                                              onChange={(ev) => handleEditChange('inter', ev.target.checked ? 3 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.payed[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.payed || 0}
                                              onChange={(ev) => handleEditChange('payed', ev.target.checked ? 4 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>

                               </div>
                               {Cookies.get('viewer')?<label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.num[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.isSip || 0}
                                              onChange={(ev) => handleEditChange('isSip', ev.target.checked ? 1 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>
                               </label>:null}

                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <hr/>
                                   <span>{sip.history[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <select value={edit.history}
                                           onChange={(ev) => handleEditChange('history', +ev.target.value)}
                                           style={{height: 40, border: '1px solid #ccc',}}>
                                       <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                       <option value="11">{sip.history1[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                       <option value="10">{sip.history[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                       <option value="12">{sip.sipHistory[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                       <option value="13">{sip.sipHistory1[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                   </select>
                               </label>
                               <label className="col-md-6 count" style={{margin: '20px 0'}}>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.rate[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.rate || 0}
                                              onChange={(ev) => handleEditChange('rate', ev.target.checked ? 1 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>
                                   <p className="d-flex flex-row align-items-center">
                                    <span style={{
                                        marginBottom: 0,
                                        width: 200
                                    }}>{sip.spy[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                       <input checked={edit.isspyadmin || 0}
                                              onChange={(ev) => handleEditChange('isspyadmin', ev.target.checked ? 1 : 0)}
                                              style={{width: 50}} type="checkbox"/>
                                   </p>
                               </label>
                               <hr/>
                               <label className="col-md-12 count" style={{margin: '20px 0'}}>
                                   <span>{sip.note[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <input value={edit.note  || ''} onChange={(ev) => handleEditChange('note', ev.target.value)}/>
                               </label>
                               <label className="col-md-12 count" style={{margin: '20px 0'}}>
                                   <span style={{margin: 0}}>{sip.ip[+localStorage.getItem('atsLang')  || 1 ]}</span>
                                   <span>{single?.sip_buddy?.ipaddr}</span>
                                   <input value={edit.ip || ''} onChange={(ev) => handleEditChange('ip', ev.target.value)}/>
                               </label>
                           </div>
                           <hr/>
                           <div className="telegram_block"><h3>Messanger</h3>
                               <span style={forTelegram?{background:'#3390ec'}:{}} onClick={()=> {
                                   if(forTelegram){
                                       setIsTelegram(open)
                                   }else{
                                       setTelegram(prevState => ({...prevState, pass: code}))
                                   }
                               }} className="telegram_icon"><TelegramIcon /> </span>
                               {forTelegram?sipTelegramData?.phone_number || forTelegram:null}
                           </div>
                           <hr/>
                           <div className="d-flex justify-content-center">
                               <h5>{crm.crm[+localStorage.getItem('atsLang')  || 1 ]}</h5>
                           </div>
                           <div>
                               {memberCrm?.map((m,_) => (
                                   <div className="crm_single_block" key={_}>
                                       <div>{m?.crm_list?.name}</div>
                                       <div className="d-flex">
                                           <button style={{background:'#ccc'}} className="addBtn" onClick={() => {setNewCrm((prev) => ({...prev,id:m?.crm_list?.id.toString(),buddy:open})); setEditNewCrm({id:m.id,type: 'view'}) }}>View</button>
                                           <button className="addBtn edit" onClick={() => {
                                               setNewCrm((prev) => ({...prev,id:m?.crm_list?.id.toString(),buddy:open})); setEditNewCrm({id:m.id,type: 'edit'}) }}>Edit</button>
                                           <button className="addBtn del" onClick={() => handleDelete(m.id)}>Delete</button>
                                       </div>
                                   </div>
                               ))}
                           </div>
                           <label className="col-md-12 count" style={{margin: '20px 0'}}>
                               <select value={newCrm.id} onChange={(ev) => {
                                   setNewCrm((prev) => ({...prev, id: ev.target.value, buddy: open}))
                                   setEditNewCrm({id:0,type: ''})
                               }}
                                       style={{height: 40, border: '1px solid #ccc',outline:'none'}}>
                                   <option key="7898789" value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                   {crmData?.map(c => (
                                       <option key={_.uniqueId()} value={c.id}>{c.name}</option>
                                   ))}
                               </select>
                           </label>
                           <div className="d-flex justify-content-center">
                               <button onClick={handleEdit} className="addBtnBig">
                                   {global.edit[+localStorage.getItem('atsLang')  || 1 ]}
                               </button>
                           </div>
                       </div>
                   </Box>
               </Fade>
           </Modal>
           {newCrm.id?<CreateAndEditCrm setEdit={setEditNewCrm} setCrm={setMemCrm} edit={editCrm} open={newCrm.id} buddy={newCrm.buddy} setOpen={setNewCrm} />:null}
           {telegram.pass?<Telegram setForTelegram={setForTelegram} open={telegram} setOpen={setTelegram} />:null}
           {isTelegram?<TelegramDelete open={isTelegram} setForTelegram={setForTelegram} setOpen={setIsTelegram} />:null}
       </>
    );
}

export default EditSip;
