import React, {useState} from 'react';
import {global} from "../translate";
import {sortableKeyboardCoordinates, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SetTimer from "./SetTimer";
import {useSelector} from "react-redux";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'

function IvrLine({setForm,s, fileData, handleDelete,ivrId = 0}) {
    const profile = useSelector(state => state.users.ursData)

    const {attributes, listeners, setNodeRef, transform, transition} =
        useSortable({id: s.id});
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 8,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 200,
            tolerance: 6,
        },
    });
    const [time,setTime] = useState(false);
    const [timeId,setTimeID] = useState(0);
    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    return (

        <div style={{...style, width: 'fit-content', margin: '10px 0'}} ref={setNodeRef} {...sensors} {...attributes}
             className="d-flex flex-row text-center">
            <div style={{width: 150,textAlign:'left'}}>
                <span style={{float:'left',width: 40, display: 'inline-block'}} {...listeners}><DragIndicatorIcon sx={{fontSize: 20}}/></span>
                {/*{profile.id?<span style={{width: 40, display: 'inline-block'}} ><AccessTimeIcon onClick={() => {setTime(true);setTimeID(s);console.log(s)}}  sx={{fontSize: 20, color:s.remind?color:'grey'}}/></span>:null}*/}
                <span style={{userSelect: 'text', pointerEvents: 'auto',marginLeft:10}}>
                    {fileData.find(f => f.fname === s.num.split('*')[1] || f.fname === s.num)?.name}
                </span>
            </div>
            <div  {...listeners} style={{width: 50, margin: '0 10px'}}>
                                   <span>
                                       {s.rx}
                                   </span>
            </div>
            <div  {...listeners} style={{width: 50, margin: '0 10px'}}>
                                   <span>
                                       {s.tx}
                                   </span>
            </div>
            <div  {...listeners} style={{width: 150}}>
                                    <span style={{width: '40%', height: 35, fontSize: 14, display: 'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                <span>-</span>
                <span style={{width: '40%', height: 35, fontSize: 14, display: 'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
            </div>
            <div  {...listeners} style={{width: 150}}>
                                    <span style={{width: '40%', height: 35, fontSize: 14}}>
                                        {s.timeStart}
                                    </span>
                <span>-</span>
                <span style={{width: '40%', height: 35, fontSize: 14}}>
                                        {s.timeEnd}
                                    </span>
            </div>
            <div style={{width: 150}} className="d-flex justify-content-center">
                <button onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(s.id);
                }} className="deleteBtn" style={{background: 'red'}}>
                    {global.delete[+localStorage.getItem('atsLang') || 1]}
                </button>
            </div>
            {time ? <SetTimer setForm={setForm} open={time} setOpen={setTime} ivr={timeId} ivrId={ivrId}/>:null}
        </div>
    );
}

export default IvrLine;
