import React, {useCallback} from 'react';
import {Handle, Position} from "@xyflow/react";
import Utils from "../Utils";
import Api from "../Api";

function CustomNode(prop) {
    const {id,data} = prop;
    const handleClick = useCallback(async (label) => {
        const color = Utils.getRandomColor();
        try {
            const d = await Api.dragSingle(label.uid, label.type.toLowerCase());
            const uniqueData = Array.from(new Set(d.data.data.map((s) => `${s.id}_${s.buddy}`)))
                .map((key) => {
                    const [id, buddy] = key.split('_');
                    return { id, buddy };
                });
            if (data?.setNodes) {
                data.setNodes((prevNodes) => {
                    const newNodes = uniqueData.map((s, i) => {
                        data.setEdges(prevState => [...prevState,{
                            id: s.id + data.lid,
                            source: data.lid,
                            sourceHandle: data.type + '_source_' + data.lid || 'source',
                            target: `${s.id}_${s.buddy}`,
                            // type: 'step',
                            targetHandle: `${d.data.label?d.data.label.toUpperCase():'SIP'}_target_` + `${s.id}_${s.buddy}` || 'target',
                        }])
                        return ({
                            id: `${s.id}_${s.buddy}`,
                            type: 'custom',
                            position: { ...label.position, x: 400 + (i + 1) * 150 },
                            data: {
                                color,
                                label: `${s.buddy}`,
                                type: d.data.label?d.data.label.toUpperCase():'SIP',
                                uid:s.buddy,
                                lid:`${s.id}_${s.buddy}`,
                                child_type:d.data.label?d.data.label.toUpperCase():'SIP',
                                position: {x: 400, y:label.position.y - 100},
                                setNodes:data.setNodes,
                                setEdges:data.setEdges
                            },

                        })
                    });

                    const filteredNodes = newNodes.filter(
                        (newNode) => !prevNodes.some((node) => node.id === newNode.id)
                    );
                    return [...prevNodes, ...filteredNodes];
                });


            } else {
                console.error("setNodes function missing");
            }


        } catch (error) {
            console.error("Error in handleClick:", error);
        }
    }, [id, data]);

    return (
        <>
            <Handle position={Position.Left} type='target' id={data.type + '_target_'  + id} />

           <div onClick={() => handleClick(data)} className="custom_node" style={data.color?{borderColor:data.color}:{}}>
               <div><b>{data.type}</b></div>
               <div>{data.label}</div>
           </div>
            <Handle type="source" position={Position.Right} id={data.type+ '_source_' + id}/>




        </>
    );
}

export default CustomNode;
